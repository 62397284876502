import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "gatsby";
import "./Glosario.css";
import { apiRequest } from "../../apiRquest/apiRequest";
import { ScrollButton } from "../scrollbutton/ScrollButton";

const GlosarieList = () => {
    const [glosarioData, setGlosarioData] = useState({});
    const sectionRefs = useRef({});
    useEffect(() => {
        const fetchGlosarioData = async () => {
            try {
                const response = await apiRequest.post("/v1/glossaries/letras/", { letra: "ABCDEFGHIJKLMNOPQRSTUVWXYZ" });
                const dataWords = {};
                response.data.forEach((item) => {
                    const letra = item.letra;
                    dataWords[letra] = dataWords[letra] || [];
                    dataWords[letra].push({
                        id: item.id,
                        title: item.title,
                        metatitle: item.metatitle,
                        slug: item.slug,
                        description: item.description,
                        htmlcontent: item.htmlcontent,
                        htmldesc: item.htmldesc,
                        status: item.status,
                        letra: item.letra,
                    });
                });
                setGlosarioData(dataWords);
            } catch (error) {
                console.error("Error fetching glosario data:", error);
            }
        };

        fetchGlosarioData();
    }, []);

    useEffect(() => {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        alphabet.split("").forEach((letra) => {
            sectionRefs.current[letra] = document.getElementById(letra);
        });
    }, [glosarioData]);

    const renderLetterSection = (letra, wordsByLetter) => {
        if (!wordsByLetter || wordsByLetter.length === 0) return null;

        return (
            <section className="fullwidth asesor" id={letra} ref={(el) => (sectionRefs.current[letra] = el)} key={letra}>
                <Container>
                    <Row className="div-border-top pb-5">
                        <Col xs={6} md={4}>
                            <p style={{ fontSize: "110px", color: "#00A3E4", lineHeight: 1 }} className="d-flex justify-content-start align-self-start">
                                {letra}
                            </p>
                        </Col>
                        <Row style={{ width: 800, padding: 0 }}>
                            {wordsByLetter.map((item, index) => (
                                <Col key={index} md={4}>
                                    <Link to={`/balanz-university/glosario-financiero/${item.slug}/`}>
                                        <p style={{ fontSize: "20px", color: "#0D2055" }}>{item.title}</p>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Row>
                </Container>
            </section>
        );
    };

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const glosarioNavigation = alphabet.split("").map((letra) => (
        <Nav.Item key={letra}>
            <Nav.Link onClick={() => scrollToSection(letra)} className="link-nav-letra">
                <span className={glosarioData[letra] && glosarioData[letra].length > 0 ? "secondary-color" : "grey-color"}>
                    {letra}
                </span>
            </Nav.Link>
        </Nav.Item>
    ));

    const scrollToSection = (letra) => {
        if (sectionRefs.current[letra]) {
            const offset = 200; 
            const yOffset = sectionRefs.current[letra].offsetTop - offset;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        }
    };

    return (
        <>
             <section className="fullwidth pb-0">
                <div className="lightgrey pb-4 pb-sm-5">
                    <Container className="d-flex align-items-center justify-content-center" style={{ padding: 0 }}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Nav>{glosarioNavigation}</Nav>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            {alphabet.split("").map((letra) => renderLetterSection(letra, glosarioData[letra]))}

            <section className="fullwidthbglosario glosario1 py-4 ">
                <Container className="d-flex align-items-center justify-content-center">
                    <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                        <Col xs={12} md={8}>
                            <h3 className="titulo banner-glosaries me-3 me-md-3 me-lg-4 me-xl-5" style={{ color: "#FFFFFF" }}>
                                <span className="highlightsbold">$0 costo de apertura y de mantenimiento.</span>
                                <span className="highlightsbold"> Sin mínimo de inversión. </span>
                                <span className="bigger-title-glosario">Transformá tu ahorro en inversión.</span>
                            </h3>
                        </Col>
                        <Col xs={12} md={2} className="d-flex align-self-center">
                            <a className="btn btn-secondary" href="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1399">
                                Abrir Cuenta
                            </a>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidthbg-glo-cursos banner-bu-glosario">
                <Container className="d-flex align-items-center">
                    <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5">
                        <Col xs={12} md={8}>
                            <div className="border-banner">
                                <h4 className="titulo me-3 me-md-3 me-lg-4 me-xl-5" style={{ color: "#0D2055" }}>
                                    <span style={{ fontWeight: "300" }}>
                                        Aprendé todo lo que tenés que saber para invertir mejor y potenciar tus ahorros con nuestros cursos.
                                    </span>
                                </h4>
                                <button className="btn btn-secondary" onClick={() => window.open("https://balanzuniversity.com/", "_blank")}>Ver cursos</button>
                            </div>
                        </Col>
                        <Col xs={12} md={2}></Col>
                    </Row>
                </Container>
            </section>
            <ScrollButton />
        </>
    );
};

export default GlosarieList;
